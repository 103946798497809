import React from "react";
import Layout from "../../components/layout/Layout";
import PasswordContent from "../../components/PasswordContent.tsx";
import { useLocation } from "@reach/router";
import { graphql, Script } from "gatsby";
import Clickmagic from "../../components/common/ClickMagic/Clickmagic";

const ResetPassword = () => {
	return (
		<Layout>
			<PasswordContent />
		</Layout>
	);
};

export const query = graphql`
	query {
		wp {
			isSeoEnabled
			isSearchEngineEnabled
		}
	}
`;

export const Head = ({ data }: any) => {
	const isSearchEnabled = data.wp.isSearchEngineEnabled;
	const location = useLocation();
	return (
		<>
			<title>Reset Password</title>
			<meta name="robots" content={!isSearchEnabled ? "noindex, nofollow" : "index, follow"} />
			<link rel="canonical" href={`${location.origin}${location.pathname}`}></link>
		</>
	);
};

export default ResetPassword;
